<template>
  <div>
    <!--薪酬记录-->
    <div>
      <div class="title">
        <div class="title_t">
          <span>薪酬记录</span>
        </div>
        <div class="title_r" @click="returnPage">
          <span>返回</span>
        </div>
      </div>

      <el-card shadow="never">
        <el-form
            ref="form"
            :model="form"
            label-width="150px">

          <div style="border-bottom: 1px solid #F6F6F6;padding-bottom: 15px;">
            <el-row :gutter="15">
              <el-col :span="6">
                <el-form-item label="姓名：" style="margin-bottom: 0!important;">
                  <span>林侃</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="周期：" style="margin-bottom: 0!important;">
                  <span>2023-07</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="月度营业额提成：" style="margin-bottom: 0!important;">
                  <span>500</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="月利润提成：" style="margin-bottom: 0!important;">
                  <span>500</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="6">
                <el-form-item label="考核奖罚：" style="margin-bottom: 0!important;">
                  <span>林侃</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="无投诉奖励：" style="margin-bottom: 0!important;">
                  <span>2023-07</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="无催单奖励：" style="margin-bottom: 0!important;">
                  <span>500</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="合格率奖励：" style="margin-bottom: 0!important;">
                  <span>500</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :spa="8">
                <el-form-item label="合格薪资：" style="margin-bottom: 0!important;">
                  <span>500</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>


          <el-row :gutte="20" style="margin-top: 30px;">
            <el-col :span="8">
              <el-form-item label="基本工资">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="岗位工资">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="利润奖金">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目奖金">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="班次补助">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="全勤工资">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目奖金">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="月度奖金">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="取消薪资">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="夜班全勤">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="夜班奖励">
                <el-input clearable v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div style="margin-top: 30px;padding-left: 20px;">
            <span style="font-size: 16px;font-weight: 500;color: #4e4e4e;">合计应付工资</span>
            <span style="font-size: 23px;font-weight: 700;color: #ff9b05;margin-left: 20px;">￥</span>
            <span style="font-size: 35px;font-weight: 700;color: #ff9b05;">7892.00</span>
          </div>
        </el-form>
      </el-card>
      <div style="margin-top: 30px;text-align: center;">
        <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>
        <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="returnPage">提交</el-button>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    data(){
      return{
        form:{}
      }
    },

    methods:{
      // 返回上级页面
      returnPage(){
        this.$emit('showEdit',false) ;
      },
    }

  }
</script>


<style lang="scss" scoped>

</style>
